import React from 'react'
import { graphql } from 'gatsby'
import lifecycle from 'react-pure-lifecycle'
import $ from 'jquery'
import ScrollToTop from 'react-scroll-up'

import Layout from '../components/layout'
import Content from '../components/content'
import Sidebar from '../components/Sidebar'

import SoloImg from '../components/solo/soloimg'

import '../components/solo/solo.css'

// create your lifecycle methods
const componentDidMount = props => {
  //Show corresponding image
  var sizeclasses = ['solo-large', 'solo-small', 'solo-medium'] // the classes you want to add
  var posiclasses = ['solo-top', 'solo-middle', 'solo-bottom'] // the classes you want to add
  var horiclasses = ['solo-right', 'solo-hmiddle', 'solo-left'] // the classes you want to add

  $('.soloitem').each(function(i) {
    // the element(s) you want to add the class to.
    $(this).addClass(
      sizeclasses[Math.floor(Math.random() * sizeclasses.length)]
    )
    $(this).addClass(
      posiclasses[Math.floor(Math.random() * posiclasses.length)]
    )
    $(this).addClass(
      horiclasses[Math.floor(Math.random() * horiclasses.length)]
    )
  })

  $(function() {
    var div = $('.soloitem').toArray()
    while (div.length > 0) {
      var idx = Math.floor(Math.random() * div.length)
      var element = div.splice(idx, 1)
      $('.content').append(element[0])
    }

    //*** EYE RANDOM ***//
    var number = $('.g-cel').length
    var random = Math.floor(Math.random() * number)
    $('.g-cel')
      .eq(random)
      .addClass('random-eye')
  })
}

// make them properties on a standard object
const methods = {
  componentDidMount,
}

const SoloPage = ({
  data: {
    allSoloJson: { edges },
  },
}) => {
  const Images = edges.map(edge => (
    <SoloImg key={edge.node.id} solo={edge.node.item} />
  ))
  return (
    <>
      <Layout place="page">
        <Sidebar />
        <Content>{Images}</Content>
        <ScrollToTop
          showUnder={480}
          style={{
            left: 100,
            bottom: 100,
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
          }}
        >
          <span>UP</span>
        </ScrollToTop>
      </Layout>
    </>
  )
}

export const soloQuery = graphql`
  query allsoloimages {
    allSoloJson(sort: { fields: item___title, order: ASC }) {
      totalCount
      edges {
        node {
          id
          item {
            title
            image {
              prettySize
              childImageSharp {
                fluid(maxHeight: 1200, fit: CONTAIN, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                original {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`

export default lifecycle(methods)(SoloPage)

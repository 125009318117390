import React from 'react'
import Img from 'gatsby-image'

const SoloImg = ({ solo }) => (
  <>
    <Img
      style={{
        width: '30%',
        margin: '20px auto 15px auto',
        pointerEvents: 'none',
      }}
      title={solo.title}
      fluid={solo.image.childImageSharp.fluid}
      className="soloitem"
      imgStyle={{
        objectFit: 'contain',
      }}
    />
  </>
)

export default SoloImg
